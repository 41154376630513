import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import cultures from '../data/json/cultures.json';
import culturesCrilaes from '../data/json/Crilaes/cultures.json';
import culturesNewWorld from '../data/json/newWorld/cultures.json';

import races from '../data/json/races.json';
import racesCrilaes from '../data/json/Crilaes/races.json';
import racesNewWorld from '../data/json/newWorld/races.json';

import cities from '../data/json/cities.json';
import citiesCrilaes from '../data/json/Crilaes/cities.json';
import citiesNewWorld from '../data/json/newWorld/cities.json';

import SubRace from './dndSubRace';

cultures=culturesNewWorld;
races=racesNewWorld;
cities=citiesNewWorld;
class DndRace extends Component {
  constructor(props) {
    super(props);
    this.state = {Id:0, openSubRace:false,};
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ Id: nextProps.Id });

}


closeSubRaces(){
  this.setState({openSubRace:false,subRace:0});

}
getSubraces(race){
  
}
toggleSubRace(e){
        
  const  subRaceId= e.currentTarget.dataset.id; 

  if (subRaceId== this.state.subRace && this.state.openSubRace){
    this.setState({
      openSubRace:!this.state.openSubRace
  
  })
 
  }else{
    
    if(subRaceId!= this.state.subRace && this.state.openSubRace){
      this.setState({
        subRace:subRaceId
    
    })
   
    }else{
      
      this.setState({
        openSubRace:!this.state.openSubRace,
        subRace:subRaceId
        
    
    })
    }
  }
    

  }
  render() {

    if(this.state.Id!=0){
    
    
      const retrievedRace = cultures.cultures.find(culture => culture.Id == this.state.Id);
      const countCities = cities.cities.filter(city => city.Culture == retrievedRace.Culture).length;
      const subRaces = retrievedRace.Races.map((item,index) => {
        const subrace = races.races.find(race => race.Id == item.Id);
        return(
        
          
        <span key={index} data-id={subrace.Id} onClick={this.toggleSubRace.bind(this)}>{ (index ? ', ' : '') }{subrace.Name}  </span>
        
      )});

     
      

    return (
      <div>
     

  <h3 data-id={this.state.subRace} onClick={this.toggleSubRace.bind(this)} >{retrievedRace.Culture}</h3>
  <div className={"collapse" + (!this.state.openSubRace ? ' in' : '')}>
   <div className="detail-table" >
                        <div className="detail-table-row">
                          <div className="detail-table-cell left">
                            Population 
                          </div>
                          <div className="detail-table-cell right">
                            {retrievedRace.Population}
                          </div>
                        </div>
                        <div className="detail-table-row">
                          <div className="detail-table-cell left">
                            Towns
                           
                          </div>
                          <div className="detail-table-cell right">
                            {countCities}
                          </div>
                        </div>
                        
                        <div className="detail-table-row">
                          <div className="detail-table-cell left">
                           Naming inspiration
                          </div>
                          <div className="detail-table-cell right">
                          {retrievedRace.Namesbase}
                          </div>
                        </div>
                        <div className="detail-table-row">
                          <div className="detail-table-cell left">
                            Races
                          </div>
                          <div className="detail-table-cell right">
                           {subRaces}
                          </div>
                        </div>
                      </div>

<p dangerouslySetInnerHTML={{ __html: retrievedRace.Description }} />


    </div>
     <div className={"collapse" + (this.state.openSubRace ? ' in' : '')}>
     <SubRace Id={this.state.subRace}/> 
            </div>

</div>
    );
  }else{
    return (

      <div></div>
    );
  }
  }
}
export default DndRace;
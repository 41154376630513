import React from "react";
import ReactDOM from "react-dom";
import App from './components/app';
import './index.css';
import * as serviceWorker from './serviceWorker';


const Index = () => {
  return <div>Hello React!</div>;
};

ReactDOM.render(<App />, document.getElementById("root"));
import React from 'react';
import { templateExtend } from '@gitgraph/core';
import {isMobile} from 'react-device-detect';

const { Gitgraph, Mode } = require("@gitgraph/react");

function Graph() {


  const options = {orientation:"vertical-reverse", import:"largeGraph",template: templateExtend("metro",{

    commit:{
 
  message: {
    displayAuthor: true,
    displayBranch: true,
    displayHash: false,
    displayBody:false,
    font: "bold 13pt Arial"
  },
  
},
branch:{
  label:{
    display:false,
    
  }
},
colors:["white","#2e3192","#f47b20","#7ac143","#ec008c","#00aeef","#ec008c","#00aeef","#7ac143","#7ac143"]

  })};

  if(isMobile) {
    options.mode=Mode.Compact;
}
  return (
   
    <Gitgraph  options={options} >
      {(gitgraph) => {
      
        // Simulate git commands with Gitgraph API.
        const master = gitgraph.branch("ESN",{label:{display:true}});
        master.commit({subject:"Member of ESN", author:"03/2016"});

        master.commit({subject:"Member of ESN Austria", author:"03/2016"
        }); 
          
          
        const austria = master.branch("ESN Austria");

        

        austria.commit({subject:"Member of ESN Uni Wien",author:"03/2016",body:"Stumbling into a recruitment event because a friend had told me we'd be going to an Erasmus evening. I found myself wanting to join this organisation and I signed up the next day."});

        const uw = austria.branch("ESN Uni Wien");
        
       
        uw.commit({subject:"WPA of ESN Uni Wien",author:"06/2016",body:"I hadn't made up my mind yet if I would be staying in Vienna or not, therefore becoming the IT responsible of this local Viennese section was one way I could assist even remotely."});
        uw.commit({subject:"Attended CEP Pecs 2016",author:"10/2016",body:"As a reward for my continuous efforts for the local section, I was awarded a spot at the Central European Platform of the Erasmus Student network. As new as I was, it was overwhelming, but a motivator to continue in this organisation. This event was a gathering of all the local sections and national organisation of ESN present in Central Europe."});
        uw.commit({subject:"Elected as President of ESN Uni Wien",author:"01/2017",body:"I wasn't sure at the time if I wanted a leading position, such responsibility did not seem to fit with me. However, after thinking it through and the encouragement of others around me I decided to take it upon me to guide my local section for the upcoming year."});
        const uw_president = uw.branch("President");
        uw_president.commit({subject:"Started mandate as President of ESN Uni Wien",author:"01/2017",body:"After a brief transition I had the pleasure to start my new mandate with a board that was supported by the entire local section."});
        uw_president.commit({subject:"Attended NP Vienna",author:"01/2017",body:"The Erasmus Student Network works on many levels, one of these levels is the national level, in my case ESN Austria. Each year this organisation holds an elective National Platform where all its members vote on the board for the upcoming year. As newly elected president and as part of my transition I was present at this platform to represent my section."});
        uw_president.commit({subject:"Introduced Slack",author:"02/2017",body:"One of the issues of my local section was the general lack of structure and communication management. The first change I made was introducing the application Slack, which was on its way up at the time, to the local section as our new central communication tool."});
        uw_president.commit({subject:"Restructured the team",author:"03/2017",body:"Our team, ESN Uni Wien, was the largest team in Austria at the time and had been split up into seperate branches that each tackled different topics. While this structure had its advantages, it also created a divide in the team. Many members did not know each other as they had been put into a different branch. The second change made during my mandate was the removal of these branches and instead we created interest groups and projects, utilizing the channel system that slack provided us."});
        uw_president.commit({subject:"Attended AGM Germany",author:"04/2017",body:"As mentioned before, ESN has many layers, the highest layer being the Annual General Meeting where all members of ESN would meet and elect its executive board for the upcoming year. As president of my section, I had the responsibility to represent us at this meeting."});
          
        austria.commit({subject:"Elected as Vice National Representative of ESN Austria",author:"06/2017",body:"The National Representative at the time, a position that represents the country on matters in the network, requested that I would apply to become her vice. I accepted the offer as my interest had been sparked after the previous international meetings I had attended and I was elected soon after."})
           const austria_vnr = austria.branch("Vice NR")
           austria_vnr.commit({subject:"Started mandate as Vice National Representative",author:"06/2017",body:"The day I was elected, my mandate also immediately started."})
         
          austria_vnr.commit({subject:"Attended CNR Thessaloniki",author:"06/2017",body:"Each country would have a representative that would meet several times a year with the representatives of the other countries. This meeting was then called the Council of National Representatives. On the day of my appointment as Vice NR, I was attending my first CNR as well as the representative of Austria."});
          
          uw_president.commit({subject:"Created an automated registration system for events",author:"08/2017",body:"A complicated system using outdated software and complicated IT systems that only one person could control was the current solution for event registrations in the local Uni Wien and most of the other Vienna sections. I spent the summer developing a system relying on simple google spreadsheets that would be easy to use and available to everyone. This system was coded with Google App Script and took care of the entire event registration including payment options such as PayPal."});

          austria_vnr.commit({subject:"Attended CNR Aveiro",author:"09/2017",body:"I had the opportunity to attend a second Council of National Representatives as the representative of Austria."});
          
          uw_president
          .commit({subject:"Attended NP Vienna",author:"10/2017",body:"As president of my local section, I represented us at the National Platform of our national organisation, ESN Austria."});

          austria_vnr.commit({subject:"Attended CEP Vilnius",author:"10/2017",author:"As Vice National Representative, I attended the Central European Platform in Vilnius where I lead the Austrian delegation throughout the event."});
          
          uw_president.commit({subject:"Organised NBM Vienna",author:"11/2017",body:"One of my goals of the mandate was to give my section and to an extent all the Viennese sections an opportunity to get in touch with the International layer of ESN and getting out name out there. I accomplished this by organising the National Boards Meeting of the Network with a team made up of members of all the Viennese sections. This was one of the first projects where all sections cooperated and pushed Vienna to new heights. It was also the first edition of the National Boards Meeting that was dedicated to the training of the members of our organisation in leadership positions."});

          austria_vnr.commit({subject:"Attended CND Lausanne",author:"12/2017", body:"As the Vice National Representative of ESN Austria I had the opportunity to represent our organisation at the Council of National Delegates. A meeting of the National Representatives and an additional delegate appointed by each country where network wide matters were discussed."});
          
          uw_president.commit({subject:"Attended NP Vienna",author:"01/2018", body:"I attended my second elective National Platform of the ESN Austria as president of my local section."});

          austria.merge({branch: austria_vnr, commitOptions:{subject:"Ended mandate as Vice National Representative of ESN Austria", author:"01/2018"}} );
          austria.commit({subject:"Elected as National Representative of ESN Austria",author:"01/2018",body:"After the experience I accumulated over the past months as the Vice, it was only normal that I applied for National Representative next."});
          const austria_nr = austria.branch("NR")
          
         
          uw_president
          .commit({subject:"Started Second Mandate as President of ESN Uni Wien",author:"01/2018",body:"While it would become challanging to combine the role of President of a local section and the role of National Representative of the national organisation. After multiple requests of my fellow teammembers I reapplied to continue the progress we had been making in the section."});

        austria_nr
        .commit({subject:"Started mandate as National Representative",author:"01/2018",body:"The previous National Representative resigned right before the elections, this meant that after being elected, I immediately started my mandate."})
        .commit({subject:"Attended CNR Belgrade",author:"02/2018",body:"My first Council Of National Representatives as the officially elected NR ws in Belgrade, where we discussed the final changes to the ESN Review, which as the restructuring of our entire organisation."})
        .commit({subject:"Attended Pre-AGM CNR Barcelona",author:"04/2018",body:"As preparation on the Annual General Meeting, the NRs meet earlier at a preparatory event called the Pre-AGM CNR. I was here to represent ESN Austria and its sections."})
        .commit({subject:"Attended AGM Costa Brava",author:"04/2018",body:"As representative of one of the national organisations of the network, I had the responsibility to lead the Austrian delegation at this Annual General Assembly."});

        master.commit({subject:"Chaired NP Switzerland as Technical Chair",author:"04/2018",body:"As a favor to one of my best friends and to grow my portfolio, I became the technical chair of the National Platform of ESN Switzerland. My main responsibilities were around the topic of livestreaming and recording the entire meeting."});

        austria_nr.commit({subject:"Attended CNR Crete",author:"05/2018",body:"As representative, I attended the Council of National Representatives, held in Crete."})
        .commit({subject:"Attended NP Graz",author:"06/2018",body:"As part of the National Board of ESN Austria, which you automatically become when elected as National Representative, I attended the National Platform in Graz to discuss what was going on in the network with our members."})
        .commit({subject:"Attended Eduk8 Kick-Off",author:"08/2018",body:"ESN has a training  programme dedicated to the development of volunteers into facilitators and trainers, called Eduk8. The Kick-Off was a small taster event to give people the opportunity to check the programme out. I attended it to satisfy my curiosities."})
        .commit({subject:"Attended NP Hungary",author:"08/2018",body:"I attended the Hungarian National Platform upon invitation of their National Representative. Primarily we discussed collaboration opportunities between Austria and Hungary for the future."});

          uw_president
          .commit({subject:"Joined the ESN International VET Project",author:"09/2018",body:"The Erasmus Student Network started working around the topic of Vocational Education training, as president of my local section I joined this project and formed a local team to work around the topic."});
        austria_nr
          .commit({subject:"Attended CNR Riga",author:"09/2018",body:"As National Representative I attended the Council of National Representatives, held in Riga."})
          .commit({subject:"Attended CEP Olomouc",author:"10/2018",body:"The Central European Platform was held in Olomouc, Czech Republic this year. I lead the Austrian delegation here throughout the entire event."})
          .commit({subject:"Attended NP Czech Republic",author:"10/2018",body:"Attended the National Platform of ESN Czech Republic upon their invitation. Primarily discussed collaboration between our countries with the focus on best practice sharing and training."})
          .commit({subject:"Attended NBM Bratislava",author:"11/2018",body:"As part of the National Board of ESN Austria, I attended the National Boards Meeting to further develop and learn the needed skills to excute my position well."})
          .commit({subject:"Attended NP Innsbruck",author:"11/2018",body:"Attended the National Platform in Innsbruck to discuss matters of international, national and local concern with the members of ESN Austria."})
          .commit({subject:"Attended CND Bucharest",author:"12/2018",body:"As the National Representative of ESN Austria I had the opportunity to represent our organisation at the Council of National Delegates. A meeting of the National Representatives and an additional delegate appointed by each country where network wide matters were discussed."});

        uw.merge({branch:uw_president,commitOptions:{subject:"Ended mandate as President of ESN Uni Wien",author:"01/2019",body:"After two years of being president of this amazing section and team, it was time to pass on the torch to a newer generation. I am still proud of my accomplishments over the years here and hope that they can be built upon over the next years."}});

          austria_nr
          .commit({subject:"Attended NP Vienna",author:"02/2019",body:"Attended the National Platform of ESN Austria where my successor would be elected for the position of National Representative."})
          .commit({subject:"Attended CNR Krakow",author:"02/2019",body:"Attended the Council of National Representatives together with my successor as part of the transition and to represent ESN Austria in the network."});

        master.commit({subject:"Attended Eduk8 Starter Poland",author:"03/2019",body:"To start my journey as trainer in this network, I attended the Eduk8 Starter. An event that focusses on giving volunteers the necessary skills to hold and design workshops and trainings."});  
        austria_nr
        .commit({subject:"Attended NP Denmark 2019",author:"04/2019",body:"Upon invitation, I attended the National Platform of ESN Denmark to learn from some of the more nordic organisations of our network."})
        .commit({subject:"Attended Pre-AGM CNR Athens ",author:"04/2019",body:"As preparation for the Annual General Meeting, I attended the Pre-AGM CNR, where we discussed the last matters concerning our second attempt at the ESN review."})
        .commit({subject:"Attended AGM Thessaloniki",author:"04/2019",body:"Lead the Austrian Delegation one final time at the Annual General Meeting of our network."});  

        austria.merge({branch:austria_nr,commitOptions:{subject:"Ended mandate as National Representative of ESN Austria",author:"05/2019",body:"All good things must come to an end and it was time to pass the torch to a newer generation of volunteers. Before my team and I became the National Board of ESN Austria, there was distrust between the national and local level of our organisation. Over the year and a half that I was active nationally, I worked hard to rebuild this trust and build a foundation that could be worked on by generations to come."}});
      austria.commit({subject:"Chaired NP Linz as Technical Chair",author:"07/2019",body:"To assist the new National Board and to stay in touch with my beloved network, I assisted them by chairing the National Platform as Technical Chair. My main responsibilities became the livestream of the event, creation of agenda and upholding our Statutes and Standing Orders throughout the event."});
      
      master.commit({subject:"Elected Structural Task Force Member",author:"06/2019",body:"After the ESN Review was voted through at the Annual General Meeting in Thessaloniki, we had transitional years ahead of us and more structural changes to be made. I was elected for the team that would be in charge of this."});
      const stf = master.branch("Structural Task Force");
      stf.commit({subject:"Started mandate as Structural Task Force member",author:"07/2019",body:"As with many elections in ESN, my mandate started as soon as I was elected. I was looking forward toward a year of contributing to this network in a different way than I had previously."});

      master.commit({subject:"Elected RTC of the Northern region of the network",author:"07/2019",body:"I decided to change my focus from where it had been so far in the network to the nordic region. For this purpose I applied to become the Regional Training Coordinator of the nordic region."});
      const rtc = master.branch("Regional Training Coordinator");
      rtc.commit({subject:"Started mandate as Regional Training Coordinator",author:"07/2019",body:"As RTC I focused on the development of the nordic countries through the training of their volunteers. Mainly done at the Regional Platform organised later this year."});
      

      stf.commit({subject:"STF meetup",author:"10/2019",body:"The Structural Task Force gathered to discuss the upcoming year and the tasks we would be focusing on."});
    
      master.commit({subject:"Became member of ESN Norway",author:"10/2019",body:"As part of my refocus and needing a new ESN home after unfortunate circumstances in ESN Austria, I decided to join ESN Norway."});
      master.commit({subject:"Became member of ESN Agder",author:"10/2019",body:"As part of my refocus and needing a new ESN home after unfortunate circumstances in ESN Austria, I decided to join ESN Agder, a Norwegian section."});
      master.commit({subject:"Became member of ESN Finland",author:"10/2019",body:"As part of my refocus and needing a new ESN home after unfortunate circumstances in ESN Austria, I decided to join ESN Finland."});
       
      stf.commit({subject:"Rewrote the Events Policy Paper",author:"10/2019",body:"In ESN we have a document that goes over the deadlines and rules of our events, with the acceptance of the ESN Review and its structural changes, this document had to be rewritten. This is one of the topics I tackled this year as part of the Structural Task force."});
      rtc.commit({subject:"Chaired the Northern European Platform Vaasa",author:"11/2019",body:"As Regional training Coordinator you automatically become chair of your respective regional platform. In my case, this was the Northern European Platform in Vaase, Finland."});

      austria.commit({subject:"Became member of ATCom",author:"11/2019",body:"As a final contribution toward the Austrian ESN Network, I decided to become part of their newly created IT committee and primarily focused on a deadline & reminder tool for the national board and sections. I also launched my previously created event registration system nationally."});
      austria.commit({subject:"Chaired NP Vienna as Technical Chair",author:"11/2019",body:"As it happened, the Austrian National Board did not have a chairing team for their National Platform. I happily applied together with two other experienced members and took this responsiblity upon us."});
      
      stf.commit({subject:"Attended CND Lisboa",author:"12/2019",body:"As member of the Structural Task Force, it was important to follow up with the national organisations of the network to see how they were handling the many changes they had to make this year."});
     

      rtc.commit({subject:"Finalized NEP Report",author:"12/2019",body:"To finalize my mandate as Regional training Coordinator, a report was written about the Northern European Platform of this year."});
      austria.merge({branch: uw, commitOptions:{subject:"Became alumni of ESN Uni Wien",author:"01/2020",body:"Not everything can be good, sometimes decisions have to be made to avoid negative stains upon a career that had been wonderful so far. After many attempts to address a growing issue of power misuse and bullying in the local section, which lead to over 15 volunteers quitting as a direct consequence, I decided that it was time to move on as there was no willingness from leadership to change."}});
      austria.commit({subject:"Chaired NP Vienna as Main Chair",author:"01/2020",body:"I felt my time in ESN Austria was coming to an end, I wanted to close it with one final event and experience as chair. This time I took the role of Main Chair upon me which meant I lead the meeting overall."});
      master.merge({branch: rtc, commitOptions:{subject:"Ended mandate as Regional Training Coordinator",author:"01/2020",body:"After handing in my NEP report, my mandate came to an end as Regional Training Coordinator."}} );
      austria.commit({subject:"Resigned from ATCom",author:"02/2020",body:"One should always appreciate their volunteers and the work they do. After multiple achievements of mine being taken by the National Board at the time and being called their own, not to mention their continuous efforts to discredit me, I decided to send in my resignation and no longer assist them in their endeavours."});

      master.commit({subject:"Appointed Eduk8 Community Manager",author:"02/2020",body:"One door closes, another one opens. Eduk8, the training programme of ESN, was in need of someone to manage their community. As I was looking for a new challange, I applied for this opportunity and was appointed."});
      const cm = master.branch("Eduk8 Community Manager");
      cm.commit({subject:"Started mandate as Community Manager of Eduk8",author:"02/2020",body:"No sooner was I appointed, my mandate started and I joined the other members of the Eduk8 team in what turned out to be a very unexpected year."});
      cm.commit({subject:"Organised the first Online Eduk8 Community Weekend",author:"03/2020",body:"With a worldwide pandemic and lockdowns in many countries, events had to be organised online and new ways of creating the feeling of a community had to be invented. I took this challange upon me and organised the first online weekend long community meeting of Eduk8 and one of the first ones, if not the first, of the network."});
      cm.commit({subject:"Gave training during the online HR & Network week",author:"05/2020",body:"With my increased involvement in Eduk8, I decided to work on my development as a trainer and organised workshops during the HR & Network week."});

      master.merge({branch: stf, commitOptions:{subject:"Ended mandate as Structural Task Force member",author:"06/2020"}} );
      master.merge({branch: austria, commitOptions:{subject:"Became alumni of ESN Austria",author:"06/2020",body:"It took me a while to take this step, I cared deeply for the network I had helped rebuild. But after many months of thought and processing, I decided to end my chapter as part of ESN Austria and become one of their alumni. I have many good memories of this network and it is sad that the ending was soured by individuals who could not take their responsibility and fight against discrimination, bullying and abuse of power in their network. But continuing to fight over this will not lead to any satisfying solutions. I end my time at ESN Austria with a smile looking back at the many amazing memories I created and the achievements of myself and my teams over the years."}} );
     
      cm.commit({subject:"Gave training during the National Boards Training",author:"09/2020",body:"With my increased involvement in Eduk8, I decided to work on my development as a trainer and organised workshops during the National Boards Training."});

      cm.commit({subject:"Gave training during the Northern European Platform 2020",author:"10/2020",body:"With my increased involvement in Eduk8, I decided to work on my development as a trainer and organised workshops during the Northern European Platform."});
      cm.commit({subject:"Gave training during the Central European Platform 2020",author:"10/2020",body:"With my increased involvement in Eduk8, I decided to work on my development as a trainer and organised workshops during the Central European Platform."});

    
      master.merge({branch: cm, commitOptions:{subject:"Ended mandate as Eduk8 Community Manager",author:"01/2021",body:"The year certainly did not go as I had anticipated, with many resignations and demotivation caused by both leadership and the worlwide pandemic we did not achieve what we set out to do. Nevertheless, I can look back at a year of learning and development."}} );

      master.commit({subject:"Appointed Eduk8 Programme Coordinator",author:"01/2021",body:"After the previous year and the lack of volunteers ready to step up, I decided to take over the coordination of the Eduk8 Programme for the year 2021."});
      const pc = master.branch("Eduk8 Programme Coordinator");
      pc.commit({subject:"Started mandate as Programme Coordinator of Eduk8",author:"02/2020"});
      }}
    </Gitgraph>
    
  );
}
export default Graph;
import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, Tooltip } from 'react-leaflet';

import guildsCrilaes from '../data/json/Crilaes/guilds.json';
import guildsNewWorld from '../data/json/newWorld/guilds.json';
import guilds from '../data/json/guilds.json';

import npcs from '../data/json/npcs.json';
import npcsCrilaes from '../data/json/Crilaes/npcs.json';
import npcsNewWorld from '../data/json/newWorld/npcs.json';

import "./guilds.scss";


class DndGuild extends Component {
  constructor(props) {
    super(props);
    this.state = {Id:0};
    guilds=guildsNewWorld;
npcs=npcsNewWorld;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.Id){
    this.setState({ Id: nextProps.Id, });
    }else{
      this.setState({ Id: 0, });
    }
    console.log(nextProps);
    console.log("got here");
}



nestPerson(person){
  let that=this;
  
  const retrievedPerson = npcs.npcs.find(npc => person.Id == npc.Id);
  var  hasChildren=false;
  var children=[];
  if(  person.Children && person.Children.length > 0){
     person.Children.map(function(child, index){
       
     children.push(that.nestPerson(child));
      console.log("yes I haz child");
        });
  }

 
var display= (

      <li>
        <div className="user">
          <img src="" className="img-responsive" />
          <div className="name">{retrievedPerson ? retrievedPerson.Name:null}</div>
          <div className="role">{person.Position}</div>
        </div>
        <ul>
       { children}
       </ul>
        </li>
        

);
console.log(display);
  return display;
}

  render() {

    if(this.state.Id!=0){
      console.log(this.state.Id);
    
      const retrievedGuild = guilds.guilds.find(guild => guild.Id == this.state.Id);
      console.log(retrievedGuild);
var members ="";
      if(retrievedGuild.Leadership && retrievedGuild.Leadership.length>0){
       members =  retrievedGuild.Leadership.map((person, index) =>  {
      
        
            return (
              <div className="org-chart">
           <ul>
           { this.nestPerson(person)}
          
            </ul>
            </div>
          )
            
        
        }) 
      }
    return (
      <div>

  <h3>{retrievedGuild.Name}</h3>
<p>{retrievedGuild.Description}</p>
<div className="pg-orgchart">

{members}
  
  </div>


    </div>
    );
  }else{
    return (

      <div></div>
    );
  }
  }
}
export default DndGuild;
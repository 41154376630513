import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
class Footer extends Component {


  render() {

    return (
      
        <footer className="ftco-footer ftco-bg-dark ftco-section">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-md-5 text-center">
                <div className="ftco-footer-widget mb-5">
                  <ul className="ftco-footer-social list-unstyled">
                    <li className="ftco-animate"><a href="https://www.linkedin.com/in/alissabulinckx/"><span className="icon-linkedin" /></a></li>
                    <li className="ftco-animate"><a href="https://www.facebook.com/alissa.bulinckx/"><span className="icon-facebook" /></a></li>
                    <li className="ftco-animate"><a href="https://www.instagram.com/alissa.bulinckx/"><span className="icon-instagram" /></a></li>
                  </ul>
                </div>
                <div className="ftco-footer-widget">
                  <h2 className="mb-3">Contact Me</h2>
                  <p className="h3 email"><a href="#">alissa.bulinckx@outlook.com</a></p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                <p>{/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                  Copyright © All rights reserved | Original template provided by <a href="https://colorlib.com" target="_blank">Colorlib</a> and adjusted by Alissa Bulinckx
                  {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}</p>
              </div>
            </div>
          </div>
        </footer>

    
    );

  }


}

export default Footer;
import React, { Component } from 'react';

import cultures from '../data/json/cultures.json';
import culturesCrilaes from '../data/json/Crilaes/cultures.json';
import culturesNewWorld from '../data/json/newWorld/cultures.json';

import races from '../data/json/races.json';
import racesCrilaes from '../data/json/Crilaes/races.json';
import racesNewWorld from '../data/json/newWorld/races.json';

import cities from '../data/json/cities.json';
import citiesCrilaes from '../data/json/Crilaes/cities.json';
import citiesNewWorld from '../data/json/newWorld/cities.json';

cultures=culturesNewWorld;
races=racesNewWorld;
cities=citiesNewWorld;
class DndSubRace extends Component {
  constructor(props) {
    super(props);
    this.state = {Id:0, };
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ Id: nextProps.Id, });
    console.log(nextProps);
    console.log("got here");
}





  render() {

    if(this.state.Id!=0){
      console.log(this.state.Id);
    
      const retrievedSubRace = races.races.find(race => race.Id == this.state.Id);
     
    
       
     
      

    return (
      <div>

  <h3>{retrievedSubRace.Name}</h3>

  

<p dangerouslySetInnerHTML={{ __html: retrievedSubRace.Description }} />


    </div>
    );
  }else{
    return (

      <div></div>
    );
  }
  }
}
export default DndSubRace;
import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';




import rivers from './data/json/rivers.json';
import riversCrilaes from './data/json/Crilaes/rivers.json';
import riversNewWorld from './data/json/newWorld/rivers.json';

import routes from './data/json/routes.json';
import routesCrilaes from './data/json/Crilaes/routes.json';
import routesNewWorld from './data/json/newWorld/routes.json';

import biomes from './data/json/biomes.json';
import biomesCrilaes from './data/json/Crilaes/biomes.json';
import biomesNewWorld from './data/json/newWorld/biomes.json';

import states from './data/json/states.json';
import statesCrilaes from './data/json/Crilaes/states.json';
import statesNewWorld from './data/json/newWorld/states.json';

import cities from './data/json/cities.json';
import citiesCrilaes from './data/json/Crilaes/cities.json';
import citiesNewWorld from './data/json/newWorld/cities.json';


import characters from './data/json/characters.json';
import charactersCrilaes from './data/json/Crilaes/characters.json';
import charactersNewWorld from './data/json/newWorld/characters.json';

import provinces from './data/json/provinces.json';
import provincesCrilaes from './data/json/Crilaes/provinces.json';
import provincesNewWorld from './data/json/newWorld/provinces.json';



import L from 'leaflet';
import { MapContainer,MapConsumer, TileLayer, Marker, Popup, GeoJSON, Tooltip ,useMapEvents } from 'react-leaflet';
import Dnd from './dnd';
import data from './data/json/cities.json';

rivers= riversNewWorld;
routes= routesNewWorld;
biomes=biomesNewWorld;
states=statesNewWorld;
cities=citiesNewWorld;
characters=charactersNewWorld;
provinces=provincesNewWorld;
data=cities;
class MarkerInfo extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
     id: props.data.markerInfoId, 
     type: props.data.markerInfoType,
     visible:props.data.showMarkerInfo,
    }
  }

	componentWillReceiveProps(nextProps) {
		this.setState({ id: nextProps.data.markerInfoId, 
      type: nextProps.data.markerInfoType,
      visible:nextProps.data.showMarkerInfo, });  
console.log(nextProps);
      console.log("got here");
  }

  cultureSwitch(param) {
    switch(param) {
      case 'Mixed':
        return ', with no race in the majority, it is viewed as a mixed culture.';
      default:
        return ', with ' + param + ' being the primary race.';
    }
  }
  
 
  render(){

    const {id,type} = this.state;
console.log("here in city " + this.state.id);
var markerInfo = (<div></div>);

    if(type=="city"){
    console.log(id);
    var res = data.cities.filter(function(item) {
      return item.Id == id;
    });

    console.log(res[0]);
    var marker= res[0]; 
var culture = this.cultureSwitch(marker.Culture);
var districts="";
if(marker.districts!=null){
 districts =  marker.Districts.map((item, index) => (
  
      <li key={item.Id}>
          {/* Passing unique value to 'key' prop, eases process for virtual DOM to remove specific element and update HTML tree  */}
          <span>{item.Id}. {item.Name}</span>
          <p>{item.Description}</p>
        
      </li>

));
}else{
   districts=(<p>{marker.Burg} does not seem to have any districts.</p>)
}
var markerImg="";
if(marker.Img!=null){
 markerImg = (<img src={`${process.env.PUBLIC_URL}/images/cities/${marker.Img}`} style={{height:'500px'}}/>);
}
const cityMarker = (  <div>  <h2>{marker.Burg}</h2>
  <div className="row">
  <div className="col-md-5">
{markerImg}
</div>
<div className="col-md-2" />
<div className="col-md-5 ">
<div className="p-5">
<p>Located in the {marker.Province} a province of the {marker.State} </p>
<p>{marker.Burg} has a population of {marker.Population} inhabitants{culture}</p>
</div>
</div>
</div>

<div className="container">
<div className="row justify-content-center mb-5 pb-5">
         <div className="col-md-7 text-center heading-section">
         
           <h3>Districts</h3>
         </div>

       </div>
       <div className="row justify-content-center mb-5 pb-5">
         <div className="col-md-7 text-center  districts">
         
         {districts}
         </div>

       </div>
  </div></div>);

  markerInfo=cityMarker;
    }

    if(type=="char"){
      console.log(id);
      var res = characters.characters.filter(function(item) {
        return item.Id == id;
      });
  
      console.log(res[0]);
      var marker= res[0]; 
      
      var birthplace = cities.cities.filter(function(item){
        return item.Id == marker.Birthplace;
      })
     

      const charMarker = (  <div>  <h2>{marker.Name}</h2>
        <div className="row">
        <div className="col-md-5">
      <img src={`${process.env.PUBLIC_URL}/images/characters/${marker.Img}`} style={{height:'500px'}}/>
      </div>
      <div className="col-md-2" />
      <div className="col-md-5 ">
      <div className="p-5">
     <table>
       <thead></thead>
       <tbody>
       <tr><th>Name:</th><td>{marker.Name}</td></tr>
       <tr><th>Race:</th><td>{marker.Race}</td></tr>
       <tr><th>Date of Birth:</th><td>{marker.Birth}</td></tr>
       <tr><th>Place of Birth:</th><td>{birthplace.Burg}</td></tr>
       </tbody>
       </table>
    
      </div>
      </div>
      </div>
      
      <div className="container">
    
             <div className="row justify-content-center mb-5 pb-5">
               <div className="col-md-7 text-center   districts">
               
               {marker.Description}
               </div>
      
             </div>
        </div>
        <div className="container">
      <div className="row justify-content-center mb-5 pb-5">
               <div className="col-md-7 text-center heading-section ">
               
                 <h3>History</h3>
               </div>
      
             </div>
             <div className="row justify-content-center mb-5 pb-5">
               <div className="col-md-7 text-center   districts">
               
              
               </div>
      
             </div>
        </div>
        </div>);
      
        markerInfo=charMarker;

    }

    if(type=="state"){
      console.log(id);
      var res = states.states.filter(function(item) {
        return item.Id == id;
      });
  
      console.log(res[0]);
      var marker= res[0]; 
  var culture = this.cultureSwitch(marker.Culture);
 
  var stateCities = cities.cities.filter(function(item){
    return item.State == marker.Id;
  })
console.log(stateCities);
  var stateChars=  characters.characters.map((e1, i) => {

    if (stateCities.filter(e2 => e2.Longitude == e1.Longitude).length > 0) {
     // If there's a match
      return (
      
      <li key={e1.Id} data-id={e1.Id} >
      
      <p> {e1.Name}</p>


      </li> )
    }}
  )

  console.log(stateChars);
  
  const stateMarker = (  <div>  <h2>{marker.State}</h2>
    <div className="row  justify-content-center mb-5 pb-5">
   
 
  <div className="col-md-7 text-center">

  <p>A {marker.Form} with a total of {marker.Population} inhabitants{culture}</p>
  <p> Ruled by {marker.Leadership}</p>

  </div>
  </div>
  
  <div className="container">
  <div className="row justify-content-center mb-5 pb-5">
           <div className="col-md-7 text-center heading-section ">
           
             <h3>Characters</h3>
           </div>
  
         </div>
         <div className="row justify-content-center mb-5 pb-5">
           <div className="col-md-7 text-center districts">
           <ul className="">
                        {stateChars}
                    </ul>
         
           </div>
  
         </div>
    </div></div>);
  
    markerInfo=stateMarker;
    }



    if(type==""){
markerInfo = (<div></div>);

    }




      return (
      <div >
{markerInfo}
     </div>
      );
     
    
  }
}
export default MarkerInfo;


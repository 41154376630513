import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import DndGeneral from './dndGeneral';

import DndMap from './dndMap';
import MarkerInfo from './markerInfo';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, Tooltip } from 'react-leaflet';
import "leaflet/dist/leaflet.css";
import "./dndMap.css";


class Dnd extends Component {
  constructor(props) {
    super(props);
    this.state = {keyMAP:1,filter:"",year:1735,markerInfoType:"", markerInfoId:0,showMarkerInfo:false,link:"Continents"};
    
  }

  retrieveMarker (id,type,showMarker) {
    console.log(id,type);
    this.setState({markerInfoType:type,markerInfoId:id,showMarkerInfo:showMarker});
    console.log(this.state);
  }
 
  changeMenu (link) {
    this.setState({link:link});
  }

  changeFilter(filter){
    this.setState({filter:filter});
    this.setState({keyMAP:Math.random()})

  }

  render() {
   
    return (
<div data-section="dnd">
    

    <section className="ftco-section training-section">
      <div className="container mt-5">
        <div className="row justify-content-center mb-5 pb-5">
          <div className="col-md-7 text-center heading-section ftco-animate">
            <span>Crilaes</span>
            <h2>A Dungeons &amp; dragons Journey</h2>
            <p>Coming soon! Still under construction!</p>
          </div>
          <div className="col-md-10 text-center  ftco-animate">
          <div className=" row justify-content-center mb-5 pb-5">
          <ul className="dndMenu">
                <li className="active"><a onClick={() => this.changeMenu("History")}  ><span>History</span></a></li>
                <li><a  onClick={() => this.changeMenu("Races")} ><span>Races</span></a></li>
                <li><a onClick={() => this.changeMenu("Continents")}><span>Continents</span></a></li>
                <li><a onClick={() => this.changeMenu("Campaigns")}><span>Campaigns</span></a></li>
                <li><a onClick={() => this.changeMenu("Guilds")} ><span>Guilds</span></a></li>
    
              </ul>
              </div>
              <div className=" row justify-content-center mb-5 pb-5">
               <DndGeneral data = {this.state}/> 
              </div>
          </div>
          <div className="col-md-10 text-center  ftco-animate">
          <ul className="dndMapFilter">
          <li><a  onClick={() => this.changeFilter("race")} ><span>Races</span></a></li>
          <li><a  onClick={() => this.changeFilter("state")} ><span>States</span></a></li>
          <li><a  onClick={() => this.changeFilter("province")} ><span>Provinces</span></a></li>
          <li><a  onClick={() => this.changeFilter("biomes")} ><span>Biomes</span></a></li>
          </ul>
          </div>
          <div className="col-md-10 text-center  ftco-animate">
          <DndMap keyMAP={this.state.keyMAP} colorFilter = {this.state.filter} year={this.state.year} sendMarker = {this.retrieveMarker.bind(this)} ></DndMap>
          </div>
          <div className="col-md-10 text-center  ftco-animate">
          <MarkerInfo data = {this.state}/>
          </div>
        </div>
   
    </div>
    </section>
    </div>
    );
  }
}
export default Dnd;
import React, { Component} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Nav from './nav';
import Header from './header';
import ESN from './esn';
import Footer from './footer';
import ParticlesBg from 'particles-bg';
import Blog from './blog';
import BlogPost from './blog/posts';
import BlogDraft from './blogDraft';
import BlogDraftPost from './blogDraft/draftPosts';
import Dnd from './dnd';
function App() {

  
  return (
    <div id="colorlib-page">
    <Nav></Nav>
    
    <Header />
    <Router >
    

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Routes>
        <Route path="/home" element={<Blog />} />
        
        
       
      
         

        <Route path="/esn" element={<ESN />}  />       
       


        <Route element={<Blog />}  path="/blog" exact />
        <Route element={<BlogPost />}  path="/blog/:slug"  />

        <Route element={<BlogDraft />}  path="/blogDraft" exact />
        <Route element={<BlogDraftPost />}  path="/blogDraft/:slug"  />
       
        <Route element={<Dnd />} path="/dnd" />
        <Route element={<ESN />} path="/esn" />
        


        <Route path="/" element={<Blog />}  />
          
        
      </Routes>
   
  </Router>
  <Footer></Footer>
  
   </div>
  );
}

export default App;

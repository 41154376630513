import React, { Component, useEffect, useState } from 'react';
import sanityClient from "../../../../client.js";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
export default function SinglePost() {
    const [postData, setPostData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == $slug]{
        title,
        slug,
        mainImage{
            asset->{
                _id,
                url
            }
        },
        body,
        "name": author->name,
        "authorImage": author->image,
        

    }`,
                { slug }
            )
            .then((data) => setPostData(data[0]))
            .catch(console.error);

    }, [slug]);

    if (!postData) return <div>Loading...</div>;
    return (
        <div data-section="single-blog">
            <section className="ftco-section">
                <div className="container mt-5">
                    <div className="row justify-content-center mb-5 pb-5">
                        <div className="col-md-7 text-center heading-section">
                            <span>Blog</span>
                            <h2>{postData.title}</h2>
                        </div>
                    </div>
                    <a className="backlink" href='/blog'>One step back, to more stories...</a>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8 single-blog">

                          

                            <BlockContent
                                blocks={postData.body}
                                projectId={sanityClient.clientConfig.projectId}
                                dataset={sanityClient.clientConfig.dataset}
                            />
                            <a className="backlink" href='/blog'>One step back, back to an overview of stories you go!</a>
                            <div className="about-author d-flex pt-5">
                                <div className="bio align-self-md-center mr-4">
                                    <img src={urlFor(postData.authorImage).width(100).url()} alt="Image placeholder" className="img-fluid mb-4" />
                                </div>
                                <div className="desc align-self-md-center">
                                    <h3>{postData.name}</h3>
                                    <p>{postData.authorBio}</p>
                                </div>
                            </div>





                        </div>

                    </div>
                    
                </div>

            </section>

       
            
        </div >




    )

}





import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM, { render } from 'react-dom';

 import world from './data/json/world.json';
 import worldCrilaes from './data/json/Crilaes/world.json';
 import worldNewWorld from './data/json/newWorld/world.json';

import borderTiles from './data/json/borders.json';
import borderTilesCrilaes from './data/json/Crilaes/borders.json';
import borderTilesNewWorld from './data/json/newWorld/borders.json';

import biomeTiles from './data/json/biomeTiles.json';
import biomeTilesCrilaes from './data/json/Crilaes/biomeTiles.json';
import biomeTilesNewWorld from './data/json/newWorld/biomeTiles.json';

import raceTiles from './data/json/raceTiles.json';
import raceTilesCrilaes from './data/json/Crilaes/raceTiles.json';
import raceTilesNewWorld from './data/json/newWorld/raceTiles.json';

import stateTiles from './data/json/stateTiles.json';
import stateTilesCrilaes from './data/json/Crilaes/stateTiles.json';
import stateTilesNewWorld from './data/json/newWorld/stateTiles.json';

import rivers from './data/json/rivers.json';
import riversCrilaes from './data/json/Crilaes/rivers.json';
import riversNewWorld from './data/json/newWorld/rivers.json';

import routes from './data/json/routes.json';
import routesCrilaes from './data/json/Crilaes/routes.json';
import routesNewWorld from './data/json/newWorld/routes.json';

import biomes from './data/json/biomes.json';
import biomesCrilaes from './data/json/Crilaes/biomes.json';
import biomesNewWorld from './data/json/newWorld/biomes.json';

import states from './data/json/states.json';
import statesCrilaes from './data/json/Crilaes/states.json';
import statesNewWorld from './data/json/newWorld/states.json';

import cities from './data/json/cities.json';
import citiesCrilaes from './data/json/Crilaes/cities.json';
import citiesNewWorld from './data/json/newWorld/cities.json';

import cultures from './data/json/cultures.json';
import culturesCrilaes from './data/json/Crilaes/cultures.json';
import culturesNewWorld from './data/json/newWorld/cultures.json';

import characters from './data/json/newWorld/characters.json';

import provinces from './data/json/provinces.json';
import provincesCrilaes from './data/json/Crilaes/provinces.json';
import provincesNewWorld from './data/json/newWorld/provinces.json';

import provinceTiles from './data/json/provinceTiles.json';
import provinceTilesCrilaes from './data/json/Crilaes/provinceTiles.json';
import provinceTilesNewWorld from './data/json/newWorld/provinceTiles.json';

import L from 'leaflet';
import 'leaflet.markercluster';
import { MapContainer,MapConsumer, TileLayer, Marker, Popup, GeoJSON, Tooltip ,useMapEvents } from 'react-leaflet';
import Dnd from './dnd';
import data from './data/json/cities.json';
import capital from './data/icons/capital.png';
import harbor from './data/icons/harbor.png';
import village from './data/icons/village.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import "overlapping-marker-spiderfier-leaflet/dist/oms";

routes=routesNewWorld;
states=statesNewWorld;
cultures=culturesNewWorld;
stateTiles=stateTilesNewWorld;
rivers=riversNewWorld;
biomes=biomesNewWorld;
biomeTiles=biomeTilesNewWorld;
borderTiles=borderTilesNewWorld;
cities= citiesNewWorld;
provinces=provincesNewWorld;
provinceTiles=provinceTilesNewWorld;
raceTiles=raceTilesNewWorld;
world=worldNewWorld;
data=cities;
var townMarkers;
var capitalMarkers;
var stateMarkers;
var charMarkers;
var markers;
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;
//const corner1 = L.latLng(19.304840290602456, 42.89147020466579)
//const corner2 = L.latLng(-37.93213555174482, -36.38587354533425)
const corner1 = L.latLng(29.304840290602456, 58.89147020466579)
const corner2 = L.latLng(-29.93213555174482, -58.38587354533425)
const bounds = L.latLngBounds(corner1, corner2)


function DndMap({sendMarker,colorFilter,keyMAP})  {

  const zoom=3;
  const currentLocation= { lat: 30, lng: 0};
  const mapYear = 1735;


  
  const mapStyle={
   fillOpacity:0.2,

  };
 
  const riverStyle={
    weight:2,
    color:'#d5e7eb',
 
      };

    const routeStyle={
      weight:1,  
      lineJoin:"round",
      lineCap:"round",
        color:'#75765F',
          };
          const crilaesStyle={
            weight:0,  
          fillOpacity:0,
          opacity:0
                };
          function clickTile(e) {
            // e = event
           
            // You can make your ajax call declaration here
            //$.ajax(... 
           
              var key = e.target.feature.properties.state;
              sendMarker(key,"state",true);
             
              // do some stuff…
            
          }
          
function selectedTileFilter(){
  switch(colorFilter){
    case "race":
      return onEachRaceTile ;

      case "state":
      return onEachStateTile  ;

      case "province":
      return onEachProvinceTile;

      case "biomes":
        return onEachBiomeTile;
      case "":
      return onEachBiomeTile;

  }

};

function selectedDataFilter() {

  switch(colorFilter){
    case "race":
      return raceTiles.features;

      case "state":
      return stateTiles.features  ;

      case "province":
      return provinceTiles.features;

      case "biomes":
        return biomeTiles.features;

      case "":
      return biomeTiles.features;

  }

};
function selectedTooltip(selectedState,selectedBiome,selectedProvince,selectedCulture) {

  switch(colorFilter){
    case "race":
      return selectedCulture.Culture;

      case "state":
      return selectedState.State  ;

      case "province":
      return selectedProvince.Province;

      case "biomes":
        return selectedBiome.Biome;

      case "":
      return selectedState.State;

  }

};

const dataFilter=selectedDataFilter(); 
const tileFilter=selectedTileFilter();     
 function onEachTile(tile,layer){
const tileType = tile.properties.type;
const tileState = tile.properties.state;
const selectedState = states.states.find(state => state.Id === tileState);
const selectedBiome = biomes.biomes.find(biome => biome.Id === tile.properties.biome);
const selectedProvince = provinces.provinces.find(province => province.Id === tile.properties.province);
const selectedCulture = cultures.cultures.find(culture => culture.Id === tile.properties.culture)

if(tileType == 'island'){

  layer.on({
    click:clickTile
  }

  )
  if(selectedState!=null){

    layer.bindTooltip(selectedTooltip(selectedState,selectedBiome,selectedProvince,selectedCulture),{className:"state-tooltip"});

  }

  }


 
  };
  function onEachBorderTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;
    const selectedState = states.states.find(state => state.Id === tileState);
  

    layer.options.opacity=0.7;
    layer.options.fillColor='';
        layer.options.color='';
    if(tileType == 'ocean'){
    
    layer.options.fillColor='#d5e7eb';
    layer.options.color='';
    }
    
    if(tileType == 'island'){
    
      
    
      
      if(selectedState!=null){
      
        layer.options.fillColor="#f2d7ac";
        layer.options.color="#000000";
        layer.options.weight=1;
        layer.options.lineJoin="round";
        layer.options.lineCap="round";
        layer.options.dashArray="4";

    
      }
    
      }
    

      if(tileType == 'lake'){
    
        layer.options.fillColor='#6A8B83';
        layer.options.color='';
        }
      if(tileType != 'island' && tileType != 'ocean'){
    
        
      }
     
     
      };
  function onEachRouteTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;

    
    if(tileType == 'searoutes'){
    
      layer.options.dashArray="4";
    layer.options.color='#6A8B83';
    }
    
     
     
      };

  function onEachBiomeTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;
    const selectedBiome = biomes.biomes.find(biome => biome.Id === tile.properties.biome);
 
    
    layer.options.opacity=0.7;
    layer.options.fillOpacity=1;
    layer.options.fillColor='';
        layer.options.color='';
  
    
    layer.options.fillColor=selectedBiome.Color;
    layer.options.color=selectedBiome.Color;
 
      };
 
  function onEachRaceTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;
    const selectedCulture = cultures.cultures.find(cultures => cultures.Id === tile.properties.culture);
 
    
    layer.options.opacity=0.7;
    layer.options.fillOpacity=1;
    layer.options.fillColor='';
        layer.options.color='';
  
    
    layer.options.fillColor=selectedCulture.Color;
    layer.options.color=selectedCulture.Color;

  }
  function onEachStateTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;
    const selectedState = states.states.find(state => state.Id === tile.properties.state);
 
    
    layer.options.opacity=0.7;
    layer.options.fillOpacity=1;
    layer.options.fillColor='';
        layer.options.color='';
  
    
    layer.options.fillColor=selectedState.Color;
    layer.options.color=selectedState.Color;

  }
  function onEachProvinceTile(tile,layer){
    const tileType = tile.properties.type;
    const tileState = tile.properties.state;
    const selectedProvince = provinces.provinces.find(provinces => provinces.Id === tile.properties.province);
 
    
    layer.options.opacity=0.7;
    layer.options.fillOpacity=1;
    layer.options.fillColor='';
        layer.options.color='';
  
    
    layer.options.fillColor=selectedProvince.Color;
    layer.options.color=selectedProvince.Color;

  }
   
  function getCapitalMarkers(){
    var capitals=[]
  var marker=""
  var tooltip=""
    data.cities.map((city, index) =>  {if(city.Capital=="capital"){
  var position = [city.Latitude, city.Longitude];
      return (
    
    capitals.push( 
      L.marker(position,{icon:chooseCityIcon(city),key:city.Id,}).bindTooltip(city.Burg,{offset:[0, 22],opacity:1,permanent:true,direction:'center'})
    
    
    )
      
  )
  }}) 
  
        return capitals;
  }
  function getCityMarkers(){
  var villages=[]
  var marker=""
  var tooltip=""
    data.cities.map((city, index) =>  {if(city.Capital!="capital"){
  var position = [city.Latitude, city.Longitude];
       return (
   
    villages.push( 
      L.marker(position,{icon:chooseCityIcon(city),key:city.Id,}).bindTooltip(city.Burg,{offset:[0, 22],opacity:1,permanent:true,direction:'center'})
    
    
    )
      
  )
  }}) 
  
        return villages;
  };
  
  function getCharMarkers(){
    var chars=[]
    var marker=""
    var tooltip=""
      characters.characters.map((char, index) =>  {

       var yearLocation= char.Location.find(e => e.Year === mapYear);
    var location = [yearLocation.Latitude, yearLocation.Longitude];
     
        return (
     
      chars.push( 
        L.marker(location,{icon:chooseCharIcon(char),key:char.Id,})
      
      
      )
        
    )
    }) 
    
          return chars;
    };
    
  
  function chooseCityIcon (city) {
    var myIcon = new L.Icon({
        iconUrl: icon,
      
        iconSize: [32,32]
    });
  
    if(city.Capital == "capital"){
         myIcon = new L.Icon({
            iconUrl: capital,
            iconSize: [16,16]
        });
      
  
        //myIcon.shadowUrl=  require('./data/icons/capital.png');
       
    }else 
    if(city.Port == "port"){
        myIcon = new L.Icon({
            iconUrl: harbor,
            iconSize: [16,16]});
    }else
  {
    myIcon = new L.Icon({
        iconUrl: village,
        iconSize: [20,20]});
  
  }
  
  return myIcon;
  };
  
  function chooseCharIcon (char) {
    var myIcon = new L.Icon({
        iconUrl: icon,
      
        iconSize: [32,32]
    });
  
    if(char.MarkerImg != ""){
         myIcon = new L.Icon({
            iconUrl: `${process.env.PUBLIC_URL}/images/characters/tokens/${char.MarkerImg}`,
            iconSize: [20,20]
        });
       
    }else {
      myIcon = new L.Icon({
        iconUrl: icon,
        iconSize: [20,20]
    });
  }
  
  return myIcon;
  };
  function townOnClick(e) {
    var key = e.sourceTarget.options.key;
    sendMarker(key,"city",true);
   
    // do some stuff…
  }
  
  function charOnClick(e) {
    var key = e.sourceTarget.options.key;
    sendMarker(key,"char",true);
  
    // do some stuff…
  }
  

   function MyComponent(){
   
   
    if(markers==null){
     markers = L.markerClusterGroup({
      maxClusterRadius: 20,
      iconCreateFunction: function (cluster) {
        var count = cluster.getChildCount();
      
        return L.divIcon({ html: count, className: 'mycluster', iconSize: L.point(40, 40) });
      },
      //Disable all of the defaults:
      spiderfyOnMaxZoom: true, showCoverageOnHover: true, zoomToBoundsOnClick: false
    });
  }
    const map = useMapEvents({
      click: () => {
     
      } ,
     

      zoom:() =>{
      
        console.log(map.getZoom());
          //clean map before zoom to avoid duplicate  markers
          map.removeLayer(markers);
 
          //cleans the marker variable to avoid duplicates
          markers.clearLayers();
     
      
        //resets any selected marker so there is no more reference to an unexisting marker
        sendMarker(0,"",false);

        if(map.getZoom()>5){

          //adds town markers to the map
            townMarkers= L.featureGroup(getCityMarkers())
           townMarkers.on('click',function(e){townOnClick(e)});
          
           markers.addLayer(townMarkers);
          
  
       
        }
        if(map.getZoom()>4){
         
          
            capitalMarkers= L.featureGroup(getCapitalMarkers())
            capitalMarkers.on('click',function(e){townOnClick(e)});
          
            markers.addLayer(capitalMarkers);
          
       
      }
       
      if(map.getZoom()>3){
        charMarkers= L.featureGroup(getCharMarkers())
        charMarkers.on('click',function(e){charOnClick(e)});

        markers.addLayer(charMarkers);
      }
        
      } 
    })       
       
      
        map.addLayer(markers);
        
    return null
  }
 
 
    
   
      return (
      
      <div className="dndMap">
      <div className="mapView">
      <MapContainer key={keyMAP} minZoom="3" className="map" maxZoom="15"   maxBoundsViscosity={1.0} maxBounds={bounds} zoom={zoom} center={currentLocation}>
   
      <GeoJSON data={dataFilter} onEachFeature={tileFilter} />
      <GeoJSON  data={borderTiles.features} onEachFeature={onEachBorderTile} />
      <GeoJSON style={crilaesStyle} data={world.features} onEachFeature={onEachTile} />
      <GeoJSON style={routeStyle} data={routes.features} onEachFeature={onEachRouteTile} />
      <GeoJSON style={riverStyle} data={rivers.features}  />
    
    
      <MyComponent />
      
      </MapContainer>
      
</div>



      </div>
      
     
      );
     
    
  
}
export default DndMap;

 


import React, { Component } from 'react';
import Graph from './graph';
import './esn.css';
import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine,
  ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
  Label, LabelList, Surface, Radar, RadarChart, PolarGrid,PieChart, Pie, Cell,
  PolarAngleAxis, PolarRadiusAxis
} from 'recharts';
import ParticlesBg from 'particles-bg';
class ESN extends Component {

  render() {
  
    const opacity = 1;

    const positionData = [
      {
        "name": "International",
        "value": 5
      },
      {
        "name": "National",
        "value": 4
      },
      {
        "name": "Local",
        "value": 3
      }
     
    ];

    const COLORS = ["#2e3192","#f47b20","#7ac143","#ec008c"];


    const eventsData = [
      { name: '2016', events: 1, category: "2016" },
      { name: '2017', events: 8, category: "2017" },
      { name: '2018', events: 15, category: "2018" },
      { name: '2019', events: 11, category: "2019" },
      { name: '2020', events: 6, category: "2020" },
      {name:'2021', events: 3, category:"2021"},
      {name:'2022',events: 2, category:"2022"},
      {name:'2023',events:1,category:"2023"}

    ];
    const messages = [
      { subject: 'ESN Uni Wien', A: 44973, B: 1 },
      { subject: 'ESN International', A: 3046, B: 1 },
      { subject: 'ESN Austria', A: 32878, B: 32878 },
      { subject: 'ESN Vienna', A: 16178, B: 1 },
    ];
    return (

      <section className="ftco-section">
        <ParticlesBg type="tadpole" num={30} color={["#2e3192", "#f47b20", "#7ac143", "#00aeef", "#ec008c"]} bg={true} />
        <div className="container">
          <div className="row justify-content-center mb-5 pb-5">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <span>My ESN Career</span>
              <h2>Statistics</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 d-flex align-self-stretch ftco-animate">
              <div className="media block-6 services p-3 py-4 d-block text-center">
                <div className="icon mb-4">	<PieChart width={300} height={300}>
  <Pie data={positionData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false}>
  {
      positionData.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index]}/>
      ))
    }
    </Pie>
</PieChart></div>
                <div className="media-body">
                  <h3 className="heading">International Positions: 5</h3>
                  <h3 className="heading">National Positions: 4</h3>
                  <h3 className="heading">Local Positions: 3</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-self-stretch ftco-animate">
              <div className="media block-6 services p-3 py-4 d-block text-center">
                <div className="icon mb-4">
                  <RadarChart cx={150} cy={150} outerRadius={150} width={300} height={300} data={messages}>
                    <PolarGrid />


                    <Radar name="Alissa Bulinckx" dataKey="A" stroke="#2e3192" fill="#2e3192" fillOpacity={0.6} />
                  </RadarChart>
                  Messages written in the</div>
                <div className="media-body">
                  <h3 className="heading"> ESN Uni Wien Slack: 44.973</h3>
                  <h3 className="heading">ESN Austria Slack: 32.878</h3>
                  <h3 className="heading">ESN Vienna Slack: 16.178</h3>
                  <h3 className="heading">ESN International Slack: 3.046</h3>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex align-self-stretch ftco-animate">
              <div className="media block-6 services p-3 py-4 d-block text-center">
                <div className="icon mb-4">
                <LineChart width={300} height={200} data={eventsData} syncId="test">
                  <XAxis hide dataKey="category" />
                  <Tooltip  />
                  <Line
                    key="events"
                    type="monotone"
                    dataKey="events"
                    stroke="#f47b20"
                    strokeWidth={5}
                  >

                  </Line>

                </LineChart></div>
                <div className="media-body">
                  <h3 className="heading">International Events attended: 29</h3>
                  <h3 className="heading">International Events chaired: 1</h3>
                  <h3 className="heading">National Platforms attended: 9</h3>
                  <h3 className="heading">National Platforms chaired: 9</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mb-5 pb-5">
            <div className="col-md-7 text-center heading-section ftco-animate">

              <h2>Timeline</h2>
            </div>
          </div>
          <div className="row d-flex justify-content-end mt-5">
            <Graph></Graph>
          </div>
        </div>
      </section>
    );
  }
}
export default ESN;
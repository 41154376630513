import React, { Component } from 'react';

class Nav extends Component {
  render(){
    return (
      <nav id="colorlib-main-nav" role="navigation">
      <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle active"><i /></a>
      <div className="js-fullheight colorlib-table">
        <div className="img" style={{backgroundImage: 'url(images/author-2.jpg)'}} />
        <div className="colorlib-table-cell js-fullheight">
          <div className="row no-gutters">
            <div className="col-md-12 text-center">
              <h1 className="mb-4"><a href="/home" className="logo">Alissa Bulinckx</a></h1>
              <ul>
               
                <li><a href="/blog" data-nav-section="blog"><span><small>01</small>Blog</span></a></li>
                <li><a href="/dnd"><span><small>02</small>DnD</span></a></li>
                <li><a href="/esn"><span><small>03</small>ESN</span></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
    ); 
    
  }
}

export default Nav;
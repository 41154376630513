import React, { Component } from 'react';

class Header extends Component {
  render(){
    return (
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="colorlib-navbar-brand">
                <a className="colorlib-logo" href="/"><span className="logo-img" style={{backgroundImage: 'url(images/person_1.jpg)'}} />Alissa Bulinckx</a>
              </div>
              <a href="#" className="js-colorlib-nav-toggle colorlib-nav-toggle"><i /></a>
            </div>
          </div>
        </div>
      </header>
    ); 
    
  }
}

export default Header;
import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Dnd from './dnd';

import cultures from './data/json/cultures.json';
import culturesCrilaes from './data/json/Crilaes/cultures.json';
import culturesNewWorld from './data/json/newWorld/cultures.json';

import continentsCrilaes from './data/json/continents.json';
import continents from './data/json/continents.json';
import continentsNewWorld from './data/json/newWorld/continents.json';

import guildDataCrilaes from './data/json/Crilaes/guilds.json';
import guildDataNewWorld from './data/json/newWorld/guilds.json';
import guildData from './data/json/guilds.json';



import information from './data/json/information.json';
import informationCrilaes from './data/json/Crilaes/information.json';
import informationNewWorld from './data/json/newWorld/information.json';

import Guild from './guilds/dndGuild';
import Race from './races/dndRace';
import Continent from './continents/dndContinent';

cultures=culturesNewWorld;
information = informationNewWorld;
guildData=guildDataNewWorld;
class DndGeneral extends Component {

    constructor(props) {
        super(props);
        this.subRaceOpen=React.createRef()
        this.state = {
            link: props.data.link,
            openContinent:false,
            openGuild:false,
            openRace:false,
            selectedGuild:null,
        }
        continents=continentsNewWorld;
    }
   
    componentWillReceiveProps(nextProps) {
        this.setState({ link: nextProps.data.link, });
        
    }
    toggleContinent(e){
        
      const  continentId= e.currentTarget.dataset.id; 

      if (continentId == this.state.continent && this.state.openContinent){
        this.setState({
          openContinent:!this.state.openContinent
      
      })

      }else{
        if(continentId!= this.state.continent && this.state.openContinent){
          this.setState({
            continent:continentId
        
        })
        }else{
          this.setState({
            openContinent:!this.state.openContinent,
            continent:continentId
        
        })
        }
      }
        

      }
    

      toggleGuild(e){
        
      const  guildId= e.currentTarget.dataset.id; 

      if (guildId== this.state.guild && this.state.openGuild){
        this.setState({
          openGuild:!this.state.openGuild
      
      })

      }else{
        if(guildId!= this.state.guild && this.state.openGuild){
          this.setState({
            guild:guildId
        
        })
        }else{
          this.setState({
            openGuild:!this.state.openGuild,
            guild:guildId
        
        })
        }
      }
        

      }
      toggleRace(e){
        
        const  raceId= e.currentTarget.dataset.id; 
  
        if (raceId== this.state.race && this.state.openRace){
          this.subRaceOpen.current.closeSubRaces();
          this.setState({
            openRace:!this.state.openRace
        
        })
      
        }else{
          if(raceId!= this.state.race && this.state.openRace){
            
            this.setState({
              race:raceId
          
          })
          }else{
            this.subRaceOpen.current.closeSubRaces();
            this.setState({
              openRace:!this.state.openRace,
              race:raceId
             
          })
   
          }
        }
      
           
          
  
        }
    render() {

        const { link } = this.state;
        if (link == "Races") {

            var raceCounter = 12;

            const races = cultures.cultures.map((item, index) =>{
            
            if(item.Id!=0){
            
            return(

                <li key={item.Id} data-id={item.Id} onClick={this.toggleRace.bind(this)}>
                    <img src={`${process.env.PUBLIC_URL}/images/cultures/${item.Img}`} />
                    <p> {item.Culture}</p>


                </li>
            
            )}});

            return (
              <div className=" row justify-content-center mb-5 pb-5">
                     <ul className="raceList">
                        {races}
                    </ul>

                    <div className=" row justify-content-center ">
                    <div className={"collapse" + (this.state.openRace ? ' in' : '')}>
                     <Race Id={this.state.race} ref={this.subRaceOpen}/> 
                            </div>
              </div>

              <p className={"collapse" + (!this.state.openRace ? ' in' : '')}>{information?.information[0]?.Race}</p>
                   
                </div>
            );
        } else {
            if (link == "Continents") {
              const continentList = continents.continents.map((item, index) =>{
            
               
                
                return(
    
                   
                  <li key={item.Id} data-id={item.Id} onClick={this.toggleContinent.bind(this)}>
                  <h3  >{item.Name}</h3>
                  
                  </li>
                
                )});
                return (
                  <div>
                  <div className=" row justify-content-center mb-5 pb-5">
                    
                      
                       
                        <p>{information?.information[0]?.Continent}</p>
                      
                     
                     
                     
                      <ul className="continentList">
                      {continentList}
                      </ul>
                      </div>
                  <div  className={"col-md-12 justify-content-center collapse" + (this.state.openContinent ? ' in' : '')}>
                <Continent Id={this.state.continent} />
          
                   
                 </div>
                   
                 </div>
                );
            } else {
                if (link == "Guilds") {

                   
                    const guilds = guildData.guilds.map((item, index) => (
        
                        <li key={item.Id} data-id={item.Id} onClick={this.toggleGuild.bind(this)}>
                      
                            <img src={`${process.env.PUBLIC_URL}/images/guilds/${item.Img}`} />
                            <p> {item.Name}</p>
        
                        
                        </li>
        
                    ));
        
                    return (
                        <div >
                            <div className=" row justify-content-center mb-5 pb-5">
                            <p>{information?.information[0]?.Guild}</p>
                            <ul className="guildList">
                                {guilds}
                            </ul>
</div>
              <div className=" row justify-content-center ">
                    <div className={"collapse" + (this.state.openGuild ? ' in' : '')}>
                     <Guild Id={this.state.guild}/> 
                            </div>
              </div>
                        </div>
                    );
                }else{
                  if(link == "History"){
                   const history=  information?.information[0]?.History.map((itemD,index)=>{
                      return(
                        <p key={itemD.Id}>{itemD.Description}</p>
                      )
                              });
                    return(
                    <div className=" row justify-content-center mb-5 pb-5">
                  { history} 
                    
                </div>
                    );
                  }else{
                    if(link == "Campaigns"){
                      return(
                      <div className=" row justify-content-center mb-5 pb-5">
                      <p>The world of Crilaes has space for many adventures &amp; adventurers, currently there is one active campaign:</p>
                      <h3>Will the darkness return</h3>
                      <p>The region Nemia, a region overtaken by beautiful grass lands and thick magical forests. The galloping of horses, a beautiful sound heard through the entire region. Snaking through the land, a river known as the Ponter, carrying the smell of the ocean and the sound of merchants, used for most of the regions’ trade. The people of Nemia have always been a happy folk, living off the land and enjoying the riches and exotic wares of the merchants that cross their lands. Whether you’re an elf, a human or an orc, Nemia is home to all and because of that, it has grown into one of the most popular regions of the realm for anyone looking for a home.</p>

<p>But where there is good fortune and happiness, there are also those who seek to take it for themselves. For the last few years the regions Bogia and Revenya have declared their interest in the lands of Nemia and have forcefully tried to take it. Pinched between two great forces, the Nemian army has done as much as it can to stop the enemy armies in their tracks, slowly they are losing ground and it’s only a matter of time before they might have to pick a side and surrender. The signs of the war are starting to spread through the country and where there is misfortune, evil forces await their time to strike.</p>


                  </div>
                      );
                    }else{

                return (
                    <div >

                    </div>
                );
              }
              }
            }
            }
        }

    }
}
export default DndGeneral;


import React, { Component, useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON, Tooltip } from 'react-leaflet';
import continentsCrilaes from '../data/json/Crilaes/continents.json';
import continents from '../data/json/continents.json';
import continentsNewWorld from '../data/json/newWorld/continents.json';
import npcs from '../data/json/npcs.json';
import npcsCrilaes from '../data/json/Crilaes/npcs.json';
import npcsNewWorld from '../data/json/newWorld/npcs.json';
import "./continents.scss";

class DndContinent extends Component {
  constructor(props) {
    super(props);
    this.state = {Id:0};
    continents=continentsNewWorld;
    npcs=npcsNewWorld;
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.Id){
    this.setState({ Id: nextProps.Id, });
    }else{
      this.setState({ Id: 0, });
    }
    
}





  render() {

    if(this.state.Id!=null){
      const retrievedContinent = continents.continents.find(continent => continent.Id == this.state.Id);
            
        const descriptions = retrievedContinent.Description.map((itemD,index)=>{
          return(
            <p key={itemD.Id}>{itemD.Description}</p>
          )

        });
         
         return(

            
         
           <div key={retrievedContinent.Id} data-id={retrievedContinent.Id} >
         <div className="detail-table">
           <div className="detail-table-row">
             <div className="detail-table-cell left">
               Population 
             </div>
             <div className="detail-table-cell right">
              {retrievedContinent.Population}
             </div>
           </div>
           <div className="detail-table-row">
             <div className="detail-table-cell left">
               Landmass
              
             </div>
             <div className="detail-table-cell right">
               {retrievedContinent.Landmass} km
             </div>
           </div>
           <div className="detail-table-row">
             <div className="detail-table-cell left">
               Biomes
             </div>
             <div className="detail-table-cell right">
               {retrievedContinent.Biomes}
             </div>
           </div>
           <div className="detail-table-row">
             <div className="detail-table-cell left">
               Races
             </div>
             <div className="detail-table-cell right">
             {retrievedContinent.Races}
             </div>
           </div>
         </div>
        
     {descriptions}
   
             </div>
          
         
         );
        }else{
          return (
      
            <div></div>
          );
        }}}
export default DndContinent;
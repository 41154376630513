import React, { Component,useEffect,useState } from 'react';
import SanityClient from "../../../client.js";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { render } from 'react-dom';
import "./blog.css";
export default function BlogDraft(){
const [allPostsData, setAllPosts]= useState(null);

// State for the list
const [list, setList] = useState(null);

// State to trigger load more
const [loadMore, setLoadMore] = useState(false)

// State of whether there is more to load
const [hasMore, setHasMore] = useState(null);

useEffect(() => {
SanityClient.fetch(
	`*[_type == "post" && author._ref in *[_type=="author" && name=="draft"]._id] | order(publishedAt desc) {
	title,
	slug,
	publishedAt,
	mainImage{
		asset->{
			_id,
			url
		}
	},"name": author->name,
	"authorImage": author->image,
	
	}`
)
.then((data) => setAllPosts(data))
.catch(console.error);
}, []);


useEffect(() => {
	if (allPostsData) {
		setList(allPostsData.slice(0,3));
		setHasMore(allPostsData.length>3);
	}
}, [allPostsData]);

// Load more button click
const handleLoadMore = () => {
	setLoadMore(true)
  }
  
  // Handle loading more articles
  useEffect(() => {
	if (loadMore && hasMore) {
	  const currentLength = list.length
	  const isMore = currentLength < allPostsData.length
	  const nextResults = isMore
		? allPostsData.slice(currentLength, currentLength + 3)
		: []
	  setList([...list, ...nextResults])
	  setLoadMore(false)
	}
  }, [loadMore, hasMore]) //eslint-disable-line
  
  //Check if there is more
  useEffect(() => {
	  if(allPostsData && list){
	const isMore = list.length < allPostsData.length
	setHasMore(isMore)
	  }
  }, [list]) //eslint-disable-line



    return (


    <div data-section="blog">
    

      <section className="ftco-section blog-section">
	      <div className="container mt-5">
	      	<div className="row justify-content-center mb-5 pb-5">
            <div className="col-md-7 text-center heading-section ftco-animate">
              <span>Blog</span>
              <h2>My mind on paper</h2>
              <p>I write to process, I hope you may read to think and learn.</p>
            </div>
          </div>
	    
<div className="row">

{list && 
list.map((post, index)=> (
	<div className="col-md-4 " key={index}>
	<div className="blog-entry">
<Link to={'/blogdraft/' + post.slug.current} key={post.slug.current}>

<span key={index}>
<a href="#" className="block-20" style={{backgroundImage: `url(${post.mainImage.asset.url})`}} ></a>

<div className="text p-4 d-block">
						<div className="meta mb-3">
						<h3 className="heading">{post.title}</h3>
						<div><a href="#"><Moment date={post.publishedAt} format="DD/MM/YYYY"/></a></div>
							<div><a href="#">{post.name}</a></div>
							
						</div>
					
						</div>
						</span>			

</Link>
</div>
</div>
))}

</div>
<div className="row justify-content-center mb-5 pb-5">
            <div className="col-md-7 text-center heading-section ftco-animate">
{hasMore ? (
        <div className="loadMoreButtom" onClick={handleLoadMore}>Load More</div>
      ) : (
        <p>No more results</p>
      )}
	  </div>
          </div>



	
	          
	         
	          
	        
	        <div className="row mt-5">
	          <div className="col text-center">
	            <div className="block-27">
	             
	            </div>
	          </div>
	     </div>
	      </div>
	    </section>


{/* loader */}
      </div>
   ); 
    


}




